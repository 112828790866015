import React, { useContext, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { navigate } from 'gatsby'

import SEO from '../../components/seo'
import Box from '../../components/box'
import { AuthProvider, AuthContext } from '../../context/auth'
import config from '../../utils/config'
import checkUtmParams from '../../utils/checkUtmParams'
import { H1 } from '..'
import Input from '../../components/input'
import axios from 'axios'
import asyncLocalStorage from '../../utils/asyncLocalStorage'
import useOnEnterEventListener from '../../utils/hooks/useOnEnterEventListener'
import Layout from '../../components/layout'
import handleAuthNetworkError from '../../utils/handleAuthNetworkError'
import { emailRegex } from '../../utils/regex'
import useHotjarScript from '../../utils/hooks/useHotjarScript'
import {
  AuthTitle,
  Button,
  Container,
  CopySubText,
  ErrorBanner,
  MiscText,
  MiscTextLink,
  MobileAuthContainer,
  WarningBanner,
} from '../signup'
import AuthSidebar from '../../components/authSidebar'
import MobileTopBar from '../../components/mobileTopBar'
import { isMobileView } from '../../utils/isMobileView'

const SigninContainer = ({ location }) => (
  <AuthProvider isSignIn={true}>
    <Signin
      isSessionExpired={location && location.state && location.state.error}
    />
  </AuthProvider>
)

const Signin = ({ isSessionExpired }) => {
  const {
    setLoading,
    checkEmailMxRecordWithDebounce,
    navigateAuth,
  } = useContext(AuthContext)
  const [email, setEmail] = useState('')
  const [error, setError] = useState(null)
  const [emailWarning, setEmailWarning] = useState(false)

  useHotjarScript()

  const debouncedEmailCheck = value => {
    if (!value) return
    return new Promise((res, rej) => {
      checkEmailMxRecordWithDebounce(value, res, rej, setEmailWarning)
    })
  }

  useEffect(() => {
    checkUtmParams()
  }, [])

  useEffect(() => {
    if (isSessionExpired) {
      setError('Session Expired. Sign-in again')
    }
  }, [])

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const result = await axios.post(config.backendUri + '/auth/sign-in', {
        email,
      })
      await asyncLocalStorage.setItem('session', result.data.session)
      await axios.post(
        config.backendUri + '/auth/confirm-email',
        {
          new: true,
        },
        {
          headers: {
            Authorization: `Bearer ${result.data.session}`,
          },
        }
      )

      navigateAuth('/login/verify')
    } catch (error) {
      setLoading(false)
      setError(handleAuthNetworkError(error))
    }
  }

  useOnEnterEventListener(emailRegex.test(email), () => {
    handleSubmit()
  })

  if (isMobileView()) {
    return (
      <MobileAuthContainer>
        <SEO title="Login" />
        <MobileTopBar backPath="/">Login</MobileTopBar>
        <div className="auth-mobile-body">
          <AuthTitle>Log in to Pulse</AuthTitle>
          <CopySubText>
            We suggest using the email address you use at work
          </CopySubText>
          <Box>
            <Input
              placeholder="your@company.com"
              onChange={({ target: { value } }) => {
                setError(null)
                setEmailWarning(null)
                setEmail(value)
                debouncedEmailCheck(value)
              }}
              value={email}
              style={{ width: '100%' }}
            />
          </Box>
          <Button
            // style={{ height: '48px', width: '100%' }}
            disabled={!emailRegex.test(email)}
            onClick={() => handleSubmit()}
          >
            <Box>Continue</Box>
          </Button>
          {error && (
            <Box style={{ marginTop: '24px' }}>
              <ErrorBanner>{error}</ErrorBanner>
            </Box>
          )}
          {emailWarning && (
            <WarningBanner style={{ marginTop: '24px' }}>
              Are you sure that address is typed correctly? If there are no
              mistakes, carry on!
            </WarningBanner>
          )}
          <Box
            style={{
              marginTop: '166px',
              // width: '270px',
              // height: '28px',
              display: 'flex',
              // paddingBottom: '24px',
              // width: '375px',
            }}
          >
            <MiscText disabled={!emailRegex.test(email)}>
              Don't have an account?{' '}
            </MiscText>
            <MiscTextLink href="/signup" style={{ marginLeft: '5px' }}>
              Sign-up
            </MiscTextLink>
          </Box>
        </div>
      </MobileAuthContainer>
    )
  }

  return (
    <Layout>
      <SEO title="Login" />
      <Container>
        <Box width="400px">
          <AuthSidebar isSignIn />
          <Box style={{ marginTop: '139px' }}>
            <AuthTitle>Log in to Pulse</AuthTitle>
            <CopySubText style={{ marginTop: '16px', marginBottom: '0px' }}>
              We suggest using the email address you use at work
            </CopySubText>
            <Box>
              <Input
                placeholder="your@company.com"
                onChange={({ target: { value } }) => {
                  setError(null)
                  setEmailWarning(null)
                  setEmail(value)
                  debouncedEmailCheck(value)
                }}
                value={email}
                style={{ width: '400px', marginTop: '24px' }}
              />
            </Box>
          </Box>
          <Box style={{ marginTop: '24px' }}>
            <Button
              // style={{ height: '48px', width: '100%' }}
              disabled={!emailRegex.test(email)}
              onClick={() => handleSubmit()}
            >
              <Box>Continue</Box>
            </Button>
            {error && (
              <Box style={{ marginTop: '16px' }}>
                <ErrorBanner>{error}</ErrorBanner>
              </Box>
            )}
            {emailWarning && (
              <WarningBanner style={{ marginTop: '16px' }}>
                Are you sure that address is typed correctly? If there are no
                mistakes, carry on!
              </WarningBanner>
            )}
            <Box
              style={{
                marginTop: '16px',
                // width: '270px',
                // height: '28px',
                display: 'flex',
                paddingBottom: '24px',
                // width: '375px',
              }}
            >
              <MiscText disabled={!emailRegex.test(email)}>
                Don't have an account?{' '}
              </MiscText>
              <MiscTextLink href="/signup" style={{ marginLeft: '5px' }}>
                Sign-up
              </MiscTextLink>
            </Box>
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}

export default SigninContainer
